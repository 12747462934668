/*      /styles/components/_header.scss     */

.header {

    background-color: $prim-blue !important;

    .root {
        flex-grow: 1;
    }

    .grow {
        flex-grow: 1;
    }

    .menu-button {
        margin-left: -12px;
        margin-right: 20px;
    }

    // background: $header-bg;
    // margin-bottom: $size-xl;
    // padding: $size-md;
    // color: white;

    // h1 {
    //     font-size: $size-lg;
    //     margin: 0;
    // }

    // h2 {
    //     color: $off-white;
    //     font-size: $size-md;
    //     font-weight: normal;
    //     margin: 0;
    // }

    // .headerlogo {
    //     height: 60px;
    // }

    // .nav-links {
    //     float: right;
    //     color: $prim-color;

    //     a {
    //         color: #000;
    //         text-decoration: none;
    //     }

    //     a:hover {
    //         color: $prim-color;
    //     }

    //     a:focus {
    //         color: #000;
    //         background: none;
    //     }
    // }

    // .dropdown-menu {
    //     font-size: 1.5rem;
    // }

    // .dropdown-item {
    //     padding: .25rem 2rem;
    // }

    // .menu-toggle-button {
    //     position: absolute;
    //     top: 8px;
    //     right: 0;
    // }

    // .menu-toggle-button:focus {
    //     outline: none;
    // }

    // .navbar-collapse {
    //     position: absolute;
    //     top: 8px;
    //     right: 0px;
    //     z-index: 1000;

    //     .navbar-nav {
    //         background: $prim-color;
    //         border-radius: 5px;

    //         a {
    //             padding: 10px 20px;
    //             color: #FFF;
    //         }
    //     }
    // }

    // @media (max-width: 992px) {
    //     .navbar-collapse {
    //         top: 55px;
    //     }
    // }
}


