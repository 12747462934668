.rules-list-item {
    min-height: 150px;
}

.rules-list-item-util {
    position: absolute;
    top: 15px;
    right: 15px;
}

.rules-list-item-fab {
    margin-left: 10px !important;
}

.rule-text {
    margin-right: 175px;
}

.subrule-text {
    margin-right: 150px;
}