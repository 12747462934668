.modal_admin {
    position: absolute;
    max-width: 600px;
    padding: 40px;
    margin: 0 auto;
    outline: none;

    .modal-title {
        margin-bottom: 15px;
    }

    .modal-description {
        margin-bottom: 5px;
        font-size: 14px;
    }

    .modal-noresults {
        margin-top: 15px;
        margin-bottom: 5px;
        font-size: 16px;
    }

    .modal-error {
        color: $prim-red;
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .modal-success {
        color: #009900;
        font-weight: bold;
        margin-bottom: 5px;
        font-size: 14px;
    }

    .modal-member {
        background-color: #ffffff;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        padding: 15px;
    }

    .modal-select {
        width: 100%;
    }

    .form-control {
        width: 100%;
    }

    .modal-button-bottom {
        margin-top: 15px;
    }

    .modal-select {
        width: 100%;
    }

    .modal-input-label {
        margin-left: 15px !important;
        margin-top: 10px !important;
    }

    .modal-datepicker {
        margin-bottom: 20px;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid $prim-blue;
    }

    .modal-datepicker-playoffs {
        margin-top: 40px;
        margin-bottom: 0px;
        padding: 3px;
    }

    .score_input {
        width: 49%;
        float: left;
        margin-right: 1%;
    }

    .score_forfeit_window {
        margin-left: 1%;
        width: 49%;
        float: right;
        margin-top: 20px;
    }
}