th {
    color: $prim-blue !important;
}

.manager-table-icon {
    margin-right: 5px;
}

.manager-table-edit {
    color: $prim-blue;
}

.manager-table-add {
    color: $prim-green;
}

.manager-table-remove {
    color: $prim-red;
}

.manager-table-view {
    color: $prim-blue;
}

.manager-table-players {
    width: auto !important;
    color: $prim-green;
}

.table-button-bottom {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.team-header {
    padding-top: 15px;
    margin-left: 20px !important;
}

.filter-input {
    margin-top: 20px;
}